var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-profile-wrapper"},[_c('div',{staticClass:"switch-language-container"},[_c('button',{staticClass:"trigger-accountlink",on:{"click":function($event){return _vm.$router.push('offlineTransaction')}}},[_c('Icon',{staticClass:"navbar-icon white",attrs:{"type":"ios-cloud-download-outline"}}),_c('span',{staticClass:"color white"},[_vm._v(_vm._s(_vm.$t('go_to_offline_transactions')))])],1),_c('img',{staticClass:"language_icon",attrs:{"src":require('@/views/resources/img/login/language.svg'),"alt":""}}),_c('LanguageSelector')],1),(_vm.versionCheckerObject && !_vm.latestVersionInUse)?_c('div',{staticClass:"update-box",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('new_version_available')))]),_c('a',{staticClass:"download-text-color",attrs:{"href":_vm.downloadUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('download_now')))])]):_vm._e(),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"onsubmit":"event.preventDefault()"}},[_c('div',{staticClass:"welcome-box"},[_c('div',{staticClass:"banner-image"},[_c('span',{staticClass:"top-welcome-text"},[_vm._v(_vm._s(_vm.$t('welcome_to_symbol')))]),_c('div',{staticClass:"bottom-welcome-text"},[_vm._v(_vm._s(_vm.$t('program_description_line1')))]),_c('div',{staticClass:"bottom-welcome-text"},[_vm._v(_vm._s(_vm.$t('program_description_line2')))]),_c('div',{staticClass:"bottom-welcome-text"},[_vm._v(_vm._s(_vm.$t('program_description_line3')))])]),_c('div',{staticClass:"login-card radius"},[_c('div',{staticClass:"img-box"}),_c('p',{staticClass:"login-title"},[_vm._v(" "+_vm._s(_vm.$t('login_to_symbol_account'))+" ")]),_c('p',{staticClass:"profile-name"},[_vm._v(" "+_vm._s(_vm.$t('profile_name'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('profile_name'),"rules":("profileExists:" + _vm.profileNames),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"field-name":"profile_name","errors":errors}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model",value:(_vm.formItems.currentProfileName),expression:"formItems.currentProfileName"}],domProps:{"value":(_vm.formItems.currentProfileName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "currentProfileName", $event.target.value)}}}),_c('AutoComplete',{class:['select-account', !_vm.profilesClassifiedByNetworkType ? 'un_click' : 'profile-name-input'],attrs:{"placeholder":" ","disabled":_vm.performingLogin},model:{value:(_vm.formItems.currentProfileName),callback:function ($$v) {_vm.$set(_vm.formItems, "currentProfileName", $$v)},expression:"formItems.currentProfileName"}},[_c('div',{staticClass:"auto-complete-sub-container scroll"},[_c('div',{staticClass:"tips-in-sub-container"},[_vm._v(" "+_vm._s(_vm.$t(_vm.profilesClassifiedByNetworkType ? 'select_a_profile' : 'no_profiles_in_database'))+" ")]),(_vm.profilesClassifiedByNetworkType)?_c('div',_vm._l((_vm.profilesClassifiedByNetworkType),function(pair){return _c('div',{key:pair.networkType},[(pair.profiles.length)?_c('div',[_c('span',{staticClass:"network-type-head-title"},[_vm._v(_vm._s(_vm.getNetworkTypeLabel(pair.networkType)))])]):_vm._e(),_vm._l((pair.profiles),function(profile,index){return _c('Option',{key:("" + (profile.profileName) + index),attrs:{"value":profile.profileName,"label":profile.profileName}},[_c('span',[_vm._v(_vm._s(profile.profileName))])])})],2)}),0):_vm._e()])])],1)]}}],null,true)}),_c('p',{staticClass:"input-password"},[_vm._v(" "+_vm._s(_vm.$t('password'))+" ")]),_c('ValidationProvider',{attrs:{"mode":"passive","vid":"password","name":_vm.$t('password'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"field-name":"password","errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.password),expression:"formItems.password"},{name:"focus",rawName:"v-focus"}],class:[!_vm.profilesClassifiedByNetworkType ? 'un_click' : ''],attrs:{"placeholder":_vm.$t('please_enter_your_account_password'),"type":"password","disabled":!_vm.profilesClassifiedByNetworkType || _vm.performingLogin},domProps:{"value":(_vm.formItems.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "password", $event.target.value)}}})])]}}],null,true)}),_c('div',{staticClass:"password-tip"},[(!!_vm.getPasswordHint().length)?_c('span',{staticClass:"prompt pointer",on:{"click":function($event){_vm.formItems.hasHint = !_vm.formItems.hasHint}}},[_vm._v(_vm._s(_vm.$t('password_hint')))]):_vm._e(),_c('span',{staticClass:"pointer create-profile",class:{ disabled: _vm.performingLogin },on:{"click":function($event){if (!_vm.performingLogin) {
                                    _vm.$router.push({
                                        name: 'profiles.importProfile.importStrategy',
                                    });
                                }}}},[_vm._v(" "+_vm._s(_vm.$t('create_a_new_account'))+" ")])]),(_vm.formItems.hasHint && !!_vm.getPasswordHint().length)?_c('div',{staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.$t('password_hint'))+": "+_vm._s(_vm.getPasswordHint())+" ")]):_vm._e(),(_vm.profilesClassifiedByNetworkType)?_c('Button',{staticClass:"pointer button",attrs:{"loading":_vm.performingLogin,"html-type":"submit"},on:{"click":function($event){$event.stopPropagation();return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('login'))+" ")]):_c('div',{staticClass:"pointer button",on:{"click":function($event){return _vm.$router.push({ name: 'profiles.importProfile.importStrategy' })}}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")])],1)])])]}}])}),_c('span',{staticClass:"version-panel"},[_vm._v(_vm._s(_vm.$t('version'))+": "+_vm._s(_vm.packageVersion))]),_c('span',{staticClass:"copyright_label"},[_vm._v(_vm._s(_vm.$t('copyright')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }